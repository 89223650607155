<template>
  <component :is="`h${h}`" :class="classes">
    <span v-html="title" />
  </component>
</template>

<script lang="ts" setup>
import type { HeadlineColors } from "~~/types/form";

interface Props {
  title: string;
  h?: 1 | 2 | 3 | 4 | 5 | 6;
  size?: "XL" | "2XL" | "3XL";
  color?: HeadlineColors;
}

const props = withDefaults(defineProps<Props>(), {
  title: "",
  h: 2,
  size: "2XL",
  color: "blue",
});

const colorClasses = {
  blue: "text-g7-blue",
  teal: "text-teal",
  orange: "text-orange-500",
  white: "text-white",
};

const sizeClasses = {
  XL: "text-sm leading-4 sm:text-base sm:leading-5 md:text-lg md:leading-7 lg:text-xl",
  "2XL":
    "text-base leading-5 sm:text-lg sm:leading-7 md:text-xl md:leading-7 lg:text-2xl lg:leading-8",
  "3XL":
    "text-lg leading-7 sm:text-xl sm:font-black sm:leading-7 md:text-2xl md:leading-8 lg:text-3xl lg:leading-9",
};
const classes = computed(() => {
  return `${colorClasses[props.color]} ${sizeClasses[props.size]}`;
});
</script>
