import hash from "object-hash";

const TAB_TOKEN_NAME = "tab_token";

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.hook("app:beforeMount", () => {
    const newToken: boolean =
      window.sessionStorage.getItem(TAB_TOKEN_NAME) === null ||
      window.name === "";
    const token = newToken
      ? hash({ rand: Math.random(), date: Date.now() })
      : window.sessionStorage.getItem(TAB_TOKEN_NAME);
    window.sessionStorage.setItem(TAB_TOKEN_NAME, token);
    window.name = token;
    nuxtApp.provide("tabToken", token);
  });
});
