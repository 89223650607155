export const useHexToRgbA = (hex: string, alpha: string | number) => {
  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5, 7), 16);

  return alpha ? `rgba(${r}, ${g}, ${b}, ${alpha})` : `rgb(${r}, ${g}, ${b})`;
};

export const decodeHtml = (html: string): string => {
  const txt = document.createElement("textarea");
  txt.innerHTML = html;
  return txt.value;
};

export const formatCurrency = (value: number): string => {
  return new Intl.NumberFormat("de-DE").format(value) + " €";
};

type dateFormat = "DD-MM-YYYY" | "DD.MM.YYYY" | "YYYY-MM" | "MON YYYY";

export const convertDate = (
  date: string,
  format: dateFormat = "DD-MM-YYYY"
): string => {
  const [year, month, day] = date.split("-");
  if (format === "YYYY-MM") {
    return `${year}-${month}`;
  }
  if (format === "MON YYYY") {
    return `${formatMonth(month)} ${year}`;
  }
  if (format === "DD.MM.YYYY") {
    return `${day}.${month}.${year}`;
  }
  return `${day}-${month}-${year}`;
};

export const convertDateFormatted = (date: string): Date => {
  const [day, month, year] = date.split("-");
  return new Date(`${year}-${month}-${day}`);
};

export function formatMonth(month: string): string {
  switch (month) {
    case "01":
      return "Januar";
    case "02":
      return "Februar";
    case "03":
      return "März";
    case "04":
      return "April";
    case "05":
      return "Mai";
    case "06":
      return "Juni";
    case "07":
      return "Juli";
    case "08":
      return "August";
    case "09":
      return "September";
    case "10":
      return "Oktober";
    case "11":
      return "November";
    case "12":
      return "Dezember";
    default:
      return "invalid month";
  }
}
