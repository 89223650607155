export default defineNuxtRouteMiddleware((to, _) => {
  if (to.path.includes("/einloggen") && useAuth().check.value) {
    return navigateTo({ path: "/mygo7seas" });
  }

  if (
    (to.path.includes("/registrierung") ||
      to.path.includes("/passwort/vergessen")) &&
    useAuth().check.value
  ) {
    return navigateTo({ path: "/mygo7seas/daten" });
  }

  const contains = ["/mygo7seas"].some((element) => to.path.includes(element));
  if (contains && useAuth().guest.value) {
    return navigateTo({ path: "/einloggen" });
  }
});
