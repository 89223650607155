import Toast from "vue-toastification/dist/index.mjs";
import "vue-toastification/dist/index.css";

const options = {
  timeout: 3000,
  hideProgressBar: true,
};

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp.use(Toast, options);
});
