import { defineRule } from "vee-validate";
import type { LabelValue, PhoneResult } from "~/types/form";

export default defineNuxtPlugin(() => {
  defineRule("couponSiblingIsSet", (value, [targets], ctx) => {
    let result = true;
    targets.split(";").forEach((target) => {
      if (ctx.form[target] && ctx.form[target].length > 0 && !value) {
        result = "Alle Coupon-Felder müssen ausgefüllt sein";
      }
    });

    return result;
  });

  defineRule("dropdownRule", (item: LabelValue) => {
    if (!item || !item.value || item.value === "") {
      return "Bitte wählen sie ein Feld aus";
    }
    return true;
  });

  defineRule("phoneRule", (item: LabelValue) => {
    if (!item) {
      return "Bitte füllen Sie das Feld aus";
    }
    if (
      !useState<PhoneResult>("phoneResult").value.isValid &&
      useState<PhoneResult>("phoneResult").value.isPossible === false
    ) {
      return "Bitte geben Sie eine gültige Telefonnummer ein";
    }
    return true;
  });

  defineRule("isBeforeToday", (item: string) => {
    if (!item) {
      return "Bitte füllen Sie das Feld aus";
    }
    const today = new Date().getTime();
    const date = convertDateFormatted(item).getTime();
    if (Number.isNaN(date)) {
      return "Ungültiges Datum";
    }
    if (date <= today) {
      return "Tag darf nicht in der Vergangenheit sein";
    }

    return true;
  });
});
