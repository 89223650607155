import type { Ref } from "vue";
import type { DefaultLayout, BookingLayout } from "@/types/layout";

export const useDefaultLayout = (): Ref<DefaultLayout> =>
  useState<DefaultLayout>("default-layout-data", () => ({
    header: {
      hotline: {
        domestic: "Hotline: 040 377072-500",
        abroad_title: "Mobile & Ausland: ",
        abroad: "+49 (0)40 377072-500",
      },
      opening_hours: ["Mo-Fr: 9:00 - 18:00 Uhr", "Sa & So: 10:00 - 18:00 Uhr"],
      action_button: {
        label: "My Go7Seas",
        context: "",
      },
    },
    footer: {
      menus: [],
      label_button: "Top",
      label_name: "Reisebüro Trans Global Tours GmbH & Co. KG",
      label_social: "Finden Sie uns auch auf",
      social_icons: [
        {
          icon: "fab fa-instagram fa-stack-1x icon-dark",
          link: "https://www.instagram.com/go7seas/",
        },
        {
          icon: "fab fa-facebook-f fa-stack-1x icon-dark",
          link: "https://www.facebook.com/go7seas/",
        },
        {
          icon: "fab fa-twitter fa-stack-1x icon-dark",
          link: "https://twitter.com/go7seas",
        },
      ],
    },
    main_menu: {
      items: [
        {
          context: "/",
          label: "start",
          active: false,
        },
        {
          context: "/reedereien",
          label: "reedereien",
          active: false,
        },
        {
          context: "/zielgebiete",
          label: "Kreuzfahrtgebiete",
          active: false,
        },
        {
          context: "/magazin",
          label: "Magazin",
          active: false,
        },
        {
          context: "/informationen",
          label: "Fragen & Antworten",
          active: false,
        },
      ],
    },
  }));

export const updateDefaultLayout = (data: DefaultLayout): void => {
  if (process.client) {
    const layout = useState<DefaultLayout>("default-layout-data");
    layout.value.header = data.header;
    layout.value.footer = data.footer;
    layout.value.main_menu = data.main_menu;
  }
};

export const updateBookingLayout = (data: BookingLayout): void => {
  const layout = useState<DefaultLayout>("default-layout-data");
  layout.value.footer = data.footer;
};
