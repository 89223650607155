<script lang="ts" setup>
import {
  AdvancedImage,
  responsive as responsivePlugin,
  placeholder,
} from "@cloudinary/vue";
import { Cloudinary } from "@cloudinary/url-gen";
import { Resize } from "@cloudinary/url-gen/actions/resize";

interface Props {
  publicId: string;
  steps?: number | Array<number>;
}

const props = withDefaults(defineProps<Props>(), {
  steps: 200,
});

const cld = new Cloudinary({
  cloud: {
    cloudName: "go7seas",
  },
  url: {
    secure: true,
  },
});

const plugins = ref([
  // lazyload(), breaks with SPA mode and loads full sized images later
  responsivePlugin({ steps: props.steps }),
  placeholder({ mode: "predominant-color" }),
]);

const image: Ref<Cloudinary | null> = ref(null);
let timeoutId: ReturnType<typeof setTimeout>;

onMounted(() => {
  timeoutId = load();
});

onUnmounted(() => {
  clearTimeout(timeoutId);
});

const load = () =>
  setTimeout(() => {
    image.value = cld
      .image(props.publicId)
      .format("auto")
      .resize(Resize.scale());
  }, 0);
</script>

<template>
  <AdvancedImage v-if="image" :cld-img="image" :plugins="plugins" />
</template>
