<template>
  <div>
    <div class="relative mr-3.5 hidden size-11 lg:inline">
      <IconsPhone class="absolute size-11 fill-white p-3" />
      <IconsCircle class="absolute size-11 fill-white" />
    </div>
    <div class="flex flex-col justify-center">
      <a :href="domesticLink">
        <span class="lg:text-xl"> {{ header.hotline.domestic }} </span>
      </a>
      <span class="flex flex-col text-sm sm:flex-row lg:text-base">
        <span class="mr-1" v-html="header.hotline.abroad_title" />
        <a class="whitespace-nowrap" :href="abroadLink">
          <span v-html="header.hotline.abroad" />
        </a>
      </span>
    </div>
    <div
      class="ml-4 hidden border-l-DEFAULT border-white pl-4 xl:flex xl:flex-col"
    >
      <span
        v-for="(hours, index) in header.opening_hours"
        :key="index"
        v-html="hours"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
const layout = useDefaultLayout();
const header = computed(() => layout.value.header);
const domesticLink = computed(() => `tel: ${header.value.hotline.domestic}`);
const abroadLink = computed(() => `tel: ${header.value.hotline.abroad}`);
</script>
