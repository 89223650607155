<template>
  <div class="flex h-full grow flex-col">
    <ClientOnly>
      <NuxtLoadingIndicator />
    </ClientOnly>

    <header
      class="fixed z-header flex h-header w-full items-center bg-g7-blue text-white lg:static"
    >
      <div class="flex grow items-center justify-between">
        <div class="cursor-pointer" @click="clearError({ redirect: '/' })">
          <CloudinaryImg
            public-id="go7seas-logo"
            :steps="[200, 400, 600]"
            class="w-40 px-2 sm:w-48 md:pl-6 xl:w-60 xl:pl-12"
          />
        </div>
        <HeaderHotline class="flex h-full items-center px-2 lg:pr-12" />
      </div>
      <div
        class="h-full cursor-pointer"
        @click="clearError({ redirect: '/mygo7seas' })"
      >
        <div
          class="hidden h-full cursor-pointer items-center px-11 uppercase lg:flex"
          :class="{
            'bg-golden-bell hover:bg-orange-600 active:bg-orange-700':
              auth.check.value,
            'bg-teal hover:bg-[#3c94be] active:bg-[#3584a9]': auth.guest.value,
          }"
        >
          <div class="relative mr-3.5 size-11">
            <IconsUser class="absolute size-11 fill-white p-3" />
            <IconsCircle class="absolute size-11 fill-white" />
          </div>
          <span>{{ layout.header.action_button.label }}</span>
        </div>
      </div>

      <div
        class="mx-2 flex size-10 cursor-pointer items-center justify-center lg:hidden"
      >
        <IconsBars class="size-8 fill-white" @click="toggleMenu" />
      </div>

      <!-- MobileMenue -->
      <ClientOnly>
        <div
          class="fixed top-[5.625rem] z-40 flex w-screen flex-col overflow-hidden transition duration-100 lg:hidden"
          :class="{ 'invisible opacity-0': !openMenu }"
          @click="toggleMenu"
        >
          <div
            v-for="(item, index) in navItems"
            :key="index"
            class="border-t-2 bg-g7-blue p-2 text-center text-sm font-black uppercase text-white sm:text-xl"
            @click="handleButton(item)"
          >
            <span v-html="item.label" />
          </div>
        </div>
      </ClientOnly>

      <div
        class="lg:hidden"
        :class="{
          'fixed inset-0 z-30 h-full w-screen bg-slate-500 opacity-50 transition duration-100':
            openMenu,
          hidden: !openMenu,
        }"
        @click="toggleMenu"
      />
    </header>

    <div class="relative top-[5.625rem] flex grow flex-col lg:static">
      <main class="min-h-60 grow">
        <!-- Navbar -->
        <div class="hidden justify-center text-g7-blue md:flex">
          <div
            v-for="(item, index) in items"
            :key="index"
            class="mx-6 my-1 flex cursor-pointer flex-col items-center whitespace-nowrap uppercase lg:text-xl xl:text-3xl"
            @click="clearError({ redirect: item.context })"
          >
            <span v-html="item.label" />
            <div class="h-0.5 w-0 transition-[width] delay-150 ease-linear" />
          </div>
        </div>

        <!-- Content -->
        <div
          class="m-auto my-8 flex max-w-xl flex-col px-4 text-center text-g7-blue"
        >
          <Headline :h="1" :title="message.title" />
          <span class="mb-8 text-lg uppercase">{{ message.subTitle }}</span>
          <span v-html="message.text" />
        </div>
      </main>
      <G7Footer :offline="error.statusCode >= 500" />
    </div>
  </div>
</template>

<script setup lang="ts">
import type { LabelContext } from "~~/types/form";

const props = defineProps<{
  error: { statusCode: number };
}>();

const layout = useDefaultLayout();
const auth = useAuth();

const generalAPIError = {
  "404": {
    title: "Fehler",
    subTitle: "Die von Ihnen angeforderte Seite konnte nicht gefunden werden",
    text: "Bitte überprüfen Sie die Schreibweise der von Ihnen aufgerufenen URL. Sollten Sie über einen Link hier her gelangt sein melden Sie uns diesen bitte an <a href='mailto:webmaster@go7seas-kreuzfahrten.de'>webmaster@go7seas-kreuzfahrten.de</a> oder wenden Sie sich an unsere Kundenbetreuung unter <a href='tel:040377072500'>Tel. 040 377072-500</a>.",
  },
  "503": {
    title: "Wir sind gleich wieder für Sie da",
    subTitle: "Leider steht die Anwendung im Moment nicht zur Verfügung",
    text: "Bitte versuchen Sie es in einigen Minuten erneut oder wenden Sie sich an unsere Kundenbetreuung unter <a href='tel:040377072500'>Tel. 040 377072-500</a>.",
  },
  "500": {
    title: "Interner Fehler",
    subTitle: "Leider ist ein Fehler aufgetreten",
    text: "Bitte versuchen Sie es in einigen Minuten erneut oder wenden Sie sich an unsere Kundenbetreuung unter <a href='tel:040377072500'>Tel. 040 377072-500</a>.",
  },
};

const message =
  generalAPIError[props.error.statusCode] || generalAPIError["500"];
const openMenu = ref(false);
const items = computed(() => layout.value.main_menu.items);
const navItems = computed(() => {
  return [
    ...items.value,
    { label: "Detailsuche", context: "/suche" },
    { label: "Newsletter", context: "/newsletter" },
    { label: "My Go7Seas", context: "/mygo7seas" },
  ];
});

function toggleMenu() {
  openMenu.value = !openMenu.value;
}

function handleButton(item: LabelContext) {
  clearError({ redirect: item.context });
}
</script>
