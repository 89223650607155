import hash from "object-hash";
import { useApiLoading } from "./loading";

export const useGo7seasApi = async <T>(url: string, options = {}) => {
  const isLoading = useApiLoading(true);
  const auth = useAuth();
  const key = hash({
    url,
    options,
  });

  options.key = key;
  options.headers = {
    ...defaultApiHeaders(),
    ...options.headers,
  };

  const { data, error, pending, refresh } = await useFetch<T>(url, {
    ...options,
    onResponse: (res) => {
      const headers = res.response.headers;
      if (headers.get("x-auth-token") !== null) {
        auth.set(headers.get("x-auth-token"));
      }
    },
  });

  isLoading.value = false;

  if (error.value) {
    if ([404, 503, 500].includes(error.value.statusCode as number)) {
      throw createError({
        statusCode:
          error.value.data.detail &&
          error.value.data.detail.includes("PageType")
            ? 404
            : error.value.statusCode,
        statusMessage: error.value.message,
        fatal: true,
      });
    }
  }

  if (error.value === null) {
    auth.refresh();
  }

  return { data, error, pending, refresh };
};

export function defaultApiHeaders(): HeadersInit {
  const auth = useAuth();
  const { $tabToken } = useNuxtApp();
  const header: Record<string, string> = {
    "Content-Type": "application/json",
  };
  if (process.client) {
    header["X-Tab-Token"] = $tabToken as string;
  }
  if (auth.check) {
    header["X-Auth-Token"] = auth.get();
  }

  return header;
}
