import { default as _91_46_46_46slug_93p0g44J9TYKMeta } from "/builds/go7seas/website/pages/[...slug].vue?macro=true";
import { default as kabinen8dlmKUGDzHMeta } from "/builds/go7seas/website/pages/auswahl/kabinen.vue?macro=true";
import { default as kategorien8P7BCG7zHUMeta } from "/builds/go7seas/website/pages/auswahl/kategorien.vue?macro=true";
import { default as transfer8O5jqPmlopMeta } from "/builds/go7seas/website/pages/auswahl/transfer.vue?macro=true";
import { default as indexvQUhje6ZHMMeta } from "/builds/go7seas/website/pages/buchung/index.vue?macro=true";
import { default as pruefenb9BihnDmTPMeta } from "/builds/go7seas/website/pages/buchung/pruefen.vue?macro=true";
import { default as zahlungsartoD3x8KxsSMMeta } from "/builds/go7seas/website/pages/buchung/zahlungsart.vue?macro=true";
import { default as callbackCQCMq8HWKFMeta } from "/builds/go7seas/website/pages/callback.vue?macro=true";
import { default as datenbankfehlerGSqu9FRxlHMeta } from "/builds/go7seas/website/pages/datenbankfehler.vue?macro=true";
import { default as einloggenLyDR1dizQ1Meta } from "/builds/go7seas/website/pages/einloggen.vue?macro=true";
import { default as _404HnysjBdU8fMeta } from "/builds/go7seas/website/pages/error/404.vue?macro=true";
import { default as _500YUTjzKUb0MMeta } from "/builds/go7seas/website/pages/error/500.vue?macro=true";
import { default as _503Ib6JegAtwCMeta } from "/builds/go7seas/website/pages/error/503.vue?macro=true";
import { default as indexh72C84hVOhMeta } from "/builds/go7seas/website/pages/index.vue?macro=true";
import { default as _91slug_93cosh3JqypvMeta } from "/builds/go7seas/website/pages/informationen/[slug].vue?macro=true";
import { default as indexAUQDdNUkFVMeta } from "/builds/go7seas/website/pages/informationen/index.vue?macro=true";
import { default as kontaktdro2lXCoohMeta } from "/builds/go7seas/website/pages/kontakt.vue?macro=true";
import { default as _91slug_93Sd3AymbdtfMeta } from "/builds/go7seas/website/pages/magazin/[slug].vue?macro=true";
import { default as indexjKuRTrdyqSMeta } from "/builds/go7seas/website/pages/magazin/index.vue?macro=true";
import { default as bewertungenLYDpe8PdzbMeta } from "/builds/go7seas/website/pages/mygo7seas/bewertungen.vue?macro=true";
import { default as profilrSj9PfOCAvMeta } from "/builds/go7seas/website/pages/mygo7seas/daten/bearbeiten/profil.vue?macro=true";
import { default as index4pDKUbIRXvMeta } from "/builds/go7seas/website/pages/mygo7seas/daten/index.vue?macro=true";
import { default as indexOCenDpgUvXMeta } from "/builds/go7seas/website/pages/mygo7seas/index.vue?macro=true";
import { default as kontaktx5d5Ii1J7bMeta } from "/builds/go7seas/website/pages/mygo7seas/kontakt.vue?macro=true";
import { default as merkzetteliFli0izrg6Meta } from "/builds/go7seas/website/pages/mygo7seas/merkzettel.vue?macro=true";
import { default as _91_46_46_46slug_93ZKnkMmD7JFMeta } from "/builds/go7seas/website/pages/newsletter/abmelden/[...slug].vue?macro=true";
import { default as anmeldendgWFHumupDMeta } from "/builds/go7seas/website/pages/newsletter/anmelden.vue?macro=true";
import { default as vergessenWKUl8Wj6b5Meta } from "/builds/go7seas/website/pages/passwort/vergessen.vue?macro=true";
import { default as reedereienkIanDOTjijMeta } from "/builds/go7seas/website/pages/reedereien.vue?macro=true";
import { default as registrierungu7PnQKAt8kMeta } from "/builds/go7seas/website/pages/registrierung.vue?macro=true";
import { default as agbX9HxUZNnuQMeta } from "/builds/go7seas/website/pages/service/agb.vue?macro=true";
import { default as datenschutzLEXeAqkDpHMeta } from "/builds/go7seas/website/pages/service/datenschutz.vue?macro=true";
import { default as gutscheinefkUGKPqEoMeta } from "/builds/go7seas/website/pages/service/gutschein.vue?macro=true";
import { default as gutscheinkaufensojxRoiASgMeta } from "/builds/go7seas/website/pages/service/gutscheinkaufen.vue?macro=true";
import { default as impressumnk12M57fulMeta } from "/builds/go7seas/website/pages/service/impressum.vue?macro=true";
import { default as jobs56GljzpYBIMeta } from "/builds/go7seas/website/pages/service/jobs.vue?macro=true";
import { default as _91_46_46_46suche_93MAQvOM2tBXMeta } from "/builds/go7seas/website/pages/suche/[...suche].vue?macro=true";
import { default as zielgebiete0upM0zUuG8Meta } from "/builds/go7seas/website/pages/zielgebiete.vue?macro=true";
export default [
  {
    name: _91_46_46_46slug_93p0g44J9TYKMeta?.name ?? "slug",
    path: _91_46_46_46slug_93p0g44J9TYKMeta?.path ?? "/:slug(.*)*",
    meta: _91_46_46_46slug_93p0g44J9TYKMeta || {},
    alias: _91_46_46_46slug_93p0g44J9TYKMeta?.alias || [],
    redirect: _91_46_46_46slug_93p0g44J9TYKMeta?.redirect,
    component: () => import("/builds/go7seas/website/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: kabinen8dlmKUGDzHMeta?.name ?? "auswahl-kabinen",
    path: kabinen8dlmKUGDzHMeta?.path ?? "/auswahl/kabinen",
    meta: kabinen8dlmKUGDzHMeta || {},
    alias: kabinen8dlmKUGDzHMeta?.alias || [],
    redirect: kabinen8dlmKUGDzHMeta?.redirect,
    component: () => import("/builds/go7seas/website/pages/auswahl/kabinen.vue").then(m => m.default || m)
  },
  {
    name: kategorien8P7BCG7zHUMeta?.name ?? "auswahl-kategorien",
    path: kategorien8P7BCG7zHUMeta?.path ?? "/auswahl/kategorien",
    meta: kategorien8P7BCG7zHUMeta || {},
    alias: kategorien8P7BCG7zHUMeta?.alias || [],
    redirect: kategorien8P7BCG7zHUMeta?.redirect,
    component: () => import("/builds/go7seas/website/pages/auswahl/kategorien.vue").then(m => m.default || m)
  },
  {
    name: transfer8O5jqPmlopMeta?.name ?? "auswahl-transfer",
    path: transfer8O5jqPmlopMeta?.path ?? "/auswahl/transfer",
    meta: transfer8O5jqPmlopMeta || {},
    alias: transfer8O5jqPmlopMeta?.alias || [],
    redirect: transfer8O5jqPmlopMeta?.redirect,
    component: () => import("/builds/go7seas/website/pages/auswahl/transfer.vue").then(m => m.default || m)
  },
  {
    name: indexvQUhje6ZHMMeta?.name ?? "buchung",
    path: indexvQUhje6ZHMMeta?.path ?? "/buchung",
    meta: indexvQUhje6ZHMMeta || {},
    alias: indexvQUhje6ZHMMeta?.alias || [],
    redirect: indexvQUhje6ZHMMeta?.redirect,
    component: () => import("/builds/go7seas/website/pages/buchung/index.vue").then(m => m.default || m)
  },
  {
    name: pruefenb9BihnDmTPMeta?.name ?? "buchung-pruefen",
    path: pruefenb9BihnDmTPMeta?.path ?? "/buchung/pruefen",
    meta: pruefenb9BihnDmTPMeta || {},
    alias: pruefenb9BihnDmTPMeta?.alias || [],
    redirect: pruefenb9BihnDmTPMeta?.redirect,
    component: () => import("/builds/go7seas/website/pages/buchung/pruefen.vue").then(m => m.default || m)
  },
  {
    name: zahlungsartoD3x8KxsSMMeta?.name ?? "buchung-zahlungsart",
    path: zahlungsartoD3x8KxsSMMeta?.path ?? "/buchung/zahlungsart",
    meta: zahlungsartoD3x8KxsSMMeta || {},
    alias: zahlungsartoD3x8KxsSMMeta?.alias || [],
    redirect: zahlungsartoD3x8KxsSMMeta?.redirect,
    component: () => import("/builds/go7seas/website/pages/buchung/zahlungsart.vue").then(m => m.default || m)
  },
  {
    name: callbackCQCMq8HWKFMeta?.name ?? "callback",
    path: callbackCQCMq8HWKFMeta?.path ?? "/callback",
    meta: callbackCQCMq8HWKFMeta || {},
    alias: callbackCQCMq8HWKFMeta?.alias || [],
    redirect: callbackCQCMq8HWKFMeta?.redirect,
    component: () => import("/builds/go7seas/website/pages/callback.vue").then(m => m.default || m)
  },
  {
    name: datenbankfehlerGSqu9FRxlHMeta?.name ?? "datenbankfehler",
    path: datenbankfehlerGSqu9FRxlHMeta?.path ?? "/datenbankfehler",
    meta: datenbankfehlerGSqu9FRxlHMeta || {},
    alias: datenbankfehlerGSqu9FRxlHMeta?.alias || [],
    redirect: datenbankfehlerGSqu9FRxlHMeta?.redirect,
    component: () => import("/builds/go7seas/website/pages/datenbankfehler.vue").then(m => m.default || m)
  },
  {
    name: einloggenLyDR1dizQ1Meta?.name ?? "einloggen",
    path: einloggenLyDR1dizQ1Meta?.path ?? "/einloggen",
    meta: einloggenLyDR1dizQ1Meta || {},
    alias: einloggenLyDR1dizQ1Meta?.alias || [],
    redirect: einloggenLyDR1dizQ1Meta?.redirect,
    component: () => import("/builds/go7seas/website/pages/einloggen.vue").then(m => m.default || m)
  },
  {
    name: _404HnysjBdU8fMeta?.name ?? "error-404",
    path: _404HnysjBdU8fMeta?.path ?? "/error/404",
    meta: _404HnysjBdU8fMeta || {},
    alias: _404HnysjBdU8fMeta?.alias || [],
    redirect: _404HnysjBdU8fMeta?.redirect,
    component: () => import("/builds/go7seas/website/pages/error/404.vue").then(m => m.default || m)
  },
  {
    name: _500YUTjzKUb0MMeta?.name ?? "error-500",
    path: _500YUTjzKUb0MMeta?.path ?? "/error/500",
    meta: _500YUTjzKUb0MMeta || {},
    alias: _500YUTjzKUb0MMeta?.alias || [],
    redirect: _500YUTjzKUb0MMeta?.redirect,
    component: () => import("/builds/go7seas/website/pages/error/500.vue").then(m => m.default || m)
  },
  {
    name: _503Ib6JegAtwCMeta?.name ?? "error-503",
    path: _503Ib6JegAtwCMeta?.path ?? "/error/503",
    meta: _503Ib6JegAtwCMeta || {},
    alias: _503Ib6JegAtwCMeta?.alias || [],
    redirect: _503Ib6JegAtwCMeta?.redirect,
    component: () => import("/builds/go7seas/website/pages/error/503.vue").then(m => m.default || m)
  },
  {
    name: indexh72C84hVOhMeta?.name ?? "index",
    path: indexh72C84hVOhMeta?.path ?? "/",
    meta: indexh72C84hVOhMeta || {},
    alias: indexh72C84hVOhMeta?.alias || [],
    redirect: indexh72C84hVOhMeta?.redirect,
    component: () => import("/builds/go7seas/website/pages/index.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93cosh3JqypvMeta?.name ?? "informationen-slug",
    path: _91slug_93cosh3JqypvMeta?.path ?? "/informationen/:slug()",
    meta: _91slug_93cosh3JqypvMeta || {},
    alias: _91slug_93cosh3JqypvMeta?.alias || [],
    redirect: _91slug_93cosh3JqypvMeta?.redirect,
    component: () => import("/builds/go7seas/website/pages/informationen/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexAUQDdNUkFVMeta?.name ?? "informationen",
    path: indexAUQDdNUkFVMeta?.path ?? "/informationen",
    meta: indexAUQDdNUkFVMeta || {},
    alias: indexAUQDdNUkFVMeta?.alias || [],
    redirect: indexAUQDdNUkFVMeta?.redirect,
    component: () => import("/builds/go7seas/website/pages/informationen/index.vue").then(m => m.default || m)
  },
  {
    name: kontaktdro2lXCoohMeta?.name ?? "kontakt",
    path: kontaktdro2lXCoohMeta?.path ?? "/kontakt",
    meta: kontaktdro2lXCoohMeta || {},
    alias: kontaktdro2lXCoohMeta?.alias || [],
    redirect: kontaktdro2lXCoohMeta?.redirect,
    component: () => import("/builds/go7seas/website/pages/kontakt.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93Sd3AymbdtfMeta?.name ?? "magazin-slug",
    path: _91slug_93Sd3AymbdtfMeta?.path ?? "/magazin/:slug()",
    meta: _91slug_93Sd3AymbdtfMeta || {},
    alias: _91slug_93Sd3AymbdtfMeta?.alias || [],
    redirect: _91slug_93Sd3AymbdtfMeta?.redirect,
    component: () => import("/builds/go7seas/website/pages/magazin/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexjKuRTrdyqSMeta?.name ?? "magazin",
    path: indexjKuRTrdyqSMeta?.path ?? "/magazin",
    meta: indexjKuRTrdyqSMeta || {},
    alias: indexjKuRTrdyqSMeta?.alias || [],
    redirect: indexjKuRTrdyqSMeta?.redirect,
    component: () => import("/builds/go7seas/website/pages/magazin/index.vue").then(m => m.default || m)
  },
  {
    name: bewertungenLYDpe8PdzbMeta?.name ?? "mygo7seas-bewertungen",
    path: bewertungenLYDpe8PdzbMeta?.path ?? "/mygo7seas/bewertungen",
    meta: bewertungenLYDpe8PdzbMeta || {},
    alias: bewertungenLYDpe8PdzbMeta?.alias || [],
    redirect: bewertungenLYDpe8PdzbMeta?.redirect,
    component: () => import("/builds/go7seas/website/pages/mygo7seas/bewertungen.vue").then(m => m.default || m)
  },
  {
    name: profilrSj9PfOCAvMeta?.name ?? "mygo7seas-daten-bearbeiten-profil",
    path: profilrSj9PfOCAvMeta?.path ?? "/mygo7seas/daten/bearbeiten/profil",
    meta: profilrSj9PfOCAvMeta || {},
    alias: profilrSj9PfOCAvMeta?.alias || [],
    redirect: profilrSj9PfOCAvMeta?.redirect,
    component: () => import("/builds/go7seas/website/pages/mygo7seas/daten/bearbeiten/profil.vue").then(m => m.default || m)
  },
  {
    name: index4pDKUbIRXvMeta?.name ?? "mygo7seas-daten",
    path: index4pDKUbIRXvMeta?.path ?? "/mygo7seas/daten",
    meta: index4pDKUbIRXvMeta || {},
    alias: index4pDKUbIRXvMeta?.alias || [],
    redirect: index4pDKUbIRXvMeta?.redirect,
    component: () => import("/builds/go7seas/website/pages/mygo7seas/daten/index.vue").then(m => m.default || m)
  },
  {
    name: indexOCenDpgUvXMeta?.name ?? "mygo7seas",
    path: indexOCenDpgUvXMeta?.path ?? "/mygo7seas",
    meta: indexOCenDpgUvXMeta || {},
    alias: indexOCenDpgUvXMeta?.alias || [],
    redirect: indexOCenDpgUvXMeta?.redirect,
    component: () => import("/builds/go7seas/website/pages/mygo7seas/index.vue").then(m => m.default || m)
  },
  {
    name: kontaktx5d5Ii1J7bMeta?.name ?? "mygo7seas-kontakt",
    path: kontaktx5d5Ii1J7bMeta?.path ?? "/mygo7seas/kontakt",
    meta: kontaktx5d5Ii1J7bMeta || {},
    alias: kontaktx5d5Ii1J7bMeta?.alias || [],
    redirect: kontaktx5d5Ii1J7bMeta?.redirect,
    component: () => import("/builds/go7seas/website/pages/mygo7seas/kontakt.vue").then(m => m.default || m)
  },
  {
    name: merkzetteliFli0izrg6Meta?.name ?? "mygo7seas-merkzettel",
    path: merkzetteliFli0izrg6Meta?.path ?? "/mygo7seas/merkzettel",
    meta: merkzetteliFli0izrg6Meta || {},
    alias: merkzetteliFli0izrg6Meta?.alias || [],
    redirect: merkzetteliFli0izrg6Meta?.redirect,
    component: () => import("/builds/go7seas/website/pages/mygo7seas/merkzettel.vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93ZKnkMmD7JFMeta?.name ?? "newsletter-abmelden-slug",
    path: _91_46_46_46slug_93ZKnkMmD7JFMeta?.path ?? "/newsletter/abmelden/:slug(.*)*",
    meta: _91_46_46_46slug_93ZKnkMmD7JFMeta || {},
    alias: _91_46_46_46slug_93ZKnkMmD7JFMeta?.alias || [],
    redirect: _91_46_46_46slug_93ZKnkMmD7JFMeta?.redirect,
    component: () => import("/builds/go7seas/website/pages/newsletter/abmelden/[...slug].vue").then(m => m.default || m)
  },
  {
    name: anmeldendgWFHumupDMeta?.name ?? "newsletter-anmelden",
    path: anmeldendgWFHumupDMeta?.path ?? "/newsletter/anmelden",
    meta: anmeldendgWFHumupDMeta || {},
    alias: anmeldendgWFHumupDMeta?.alias || [],
    redirect: anmeldendgWFHumupDMeta?.redirect,
    component: () => import("/builds/go7seas/website/pages/newsletter/anmelden.vue").then(m => m.default || m)
  },
  {
    name: vergessenWKUl8Wj6b5Meta?.name ?? "passwort-vergessen",
    path: vergessenWKUl8Wj6b5Meta?.path ?? "/passwort/vergessen",
    meta: vergessenWKUl8Wj6b5Meta || {},
    alias: vergessenWKUl8Wj6b5Meta?.alias || [],
    redirect: vergessenWKUl8Wj6b5Meta?.redirect,
    component: () => import("/builds/go7seas/website/pages/passwort/vergessen.vue").then(m => m.default || m)
  },
  {
    name: reedereienkIanDOTjijMeta?.name ?? "reedereien",
    path: reedereienkIanDOTjijMeta?.path ?? "/reedereien",
    meta: reedereienkIanDOTjijMeta || {},
    alias: reedereienkIanDOTjijMeta?.alias || [],
    redirect: reedereienkIanDOTjijMeta?.redirect,
    component: () => import("/builds/go7seas/website/pages/reedereien.vue").then(m => m.default || m)
  },
  {
    name: registrierungu7PnQKAt8kMeta?.name ?? "registrierung",
    path: registrierungu7PnQKAt8kMeta?.path ?? "/registrierung",
    meta: registrierungu7PnQKAt8kMeta || {},
    alias: registrierungu7PnQKAt8kMeta?.alias || [],
    redirect: registrierungu7PnQKAt8kMeta?.redirect,
    component: () => import("/builds/go7seas/website/pages/registrierung.vue").then(m => m.default || m)
  },
  {
    name: agbX9HxUZNnuQMeta?.name ?? "service-agb",
    path: agbX9HxUZNnuQMeta?.path ?? "/service/agb",
    meta: agbX9HxUZNnuQMeta || {},
    alias: agbX9HxUZNnuQMeta?.alias || [],
    redirect: agbX9HxUZNnuQMeta?.redirect,
    component: () => import("/builds/go7seas/website/pages/service/agb.vue").then(m => m.default || m)
  },
  {
    name: datenschutzLEXeAqkDpHMeta?.name ?? "service-datenschutz",
    path: datenschutzLEXeAqkDpHMeta?.path ?? "/service/datenschutz",
    meta: datenschutzLEXeAqkDpHMeta || {},
    alias: datenschutzLEXeAqkDpHMeta?.alias || [],
    redirect: datenschutzLEXeAqkDpHMeta?.redirect,
    component: () => import("/builds/go7seas/website/pages/service/datenschutz.vue").then(m => m.default || m)
  },
  {
    name: gutscheinefkUGKPqEoMeta?.name ?? "service-gutschein",
    path: gutscheinefkUGKPqEoMeta?.path ?? "/service/gutschein",
    meta: gutscheinefkUGKPqEoMeta || {},
    alias: gutscheinefkUGKPqEoMeta?.alias || [],
    redirect: gutscheinefkUGKPqEoMeta?.redirect,
    component: () => import("/builds/go7seas/website/pages/service/gutschein.vue").then(m => m.default || m)
  },
  {
    name: gutscheinkaufensojxRoiASgMeta?.name ?? "service-gutscheinkaufen",
    path: gutscheinkaufensojxRoiASgMeta?.path ?? "/service/gutscheinkaufen",
    meta: gutscheinkaufensojxRoiASgMeta || {},
    alias: gutscheinkaufensojxRoiASgMeta?.alias || [],
    redirect: gutscheinkaufensojxRoiASgMeta?.redirect,
    component: () => import("/builds/go7seas/website/pages/service/gutscheinkaufen.vue").then(m => m.default || m)
  },
  {
    name: impressumnk12M57fulMeta?.name ?? "service-impressum",
    path: impressumnk12M57fulMeta?.path ?? "/service/impressum",
    meta: impressumnk12M57fulMeta || {},
    alias: impressumnk12M57fulMeta?.alias || [],
    redirect: impressumnk12M57fulMeta?.redirect,
    component: () => import("/builds/go7seas/website/pages/service/impressum.vue").then(m => m.default || m)
  },
  {
    name: jobs56GljzpYBIMeta?.name ?? "service-jobs",
    path: jobs56GljzpYBIMeta?.path ?? "/service/jobs",
    meta: jobs56GljzpYBIMeta || {},
    alias: jobs56GljzpYBIMeta?.alias || [],
    redirect: jobs56GljzpYBIMeta?.redirect,
    component: () => import("/builds/go7seas/website/pages/service/jobs.vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46suche_93MAQvOM2tBXMeta?.name ?? "suche-suche",
    path: _91_46_46_46suche_93MAQvOM2tBXMeta?.path ?? "/suche/:suche(.*)*",
    meta: _91_46_46_46suche_93MAQvOM2tBXMeta || {},
    alias: _91_46_46_46suche_93MAQvOM2tBXMeta?.alias || [],
    redirect: _91_46_46_46suche_93MAQvOM2tBXMeta?.redirect,
    component: () => import("/builds/go7seas/website/pages/suche/[...suche].vue").then(m => m.default || m)
  },
  {
    name: zielgebiete0upM0zUuG8Meta?.name ?? "zielgebiete",
    path: zielgebiete0upM0zUuG8Meta?.path ?? "/zielgebiete",
    meta: zielgebiete0upM0zUuG8Meta || {},
    alias: zielgebiete0upM0zUuG8Meta?.alias || [],
    redirect: zielgebiete0upM0zUuG8Meta?.redirect,
    component: () => import("/builds/go7seas/website/pages/zielgebiete.vue").then(m => m.default || m)
  }
]