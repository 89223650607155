export default defineNuxtPlugin((nuxtApp) => {
  const exceptions = [
    "ship_details",
    "itinerary",
    "life_on_board",
    "cruise_experts",
    "included_services",
  ];
  nuxtApp.$router.options.scrollBehavior = (to) => {
    const contains = exceptions.some((element) => {
      return to.path.includes(element);
    });
    if (!contains) {
      return { left: 0, top: 0 };
    }
  };
});
