<template>
  <button
    class="relative mt-0.5 hidden size-20 bg-white px-3 text-center lg:flex lg:flex-col"
    @click="scroll"
  >
    <IconsUp class="absolute size-14 fill-g7-blue" />
    <div class="absolute left-5 top-9 text-2xl text-g7-blue">
      {{ label }}
    </div>
  </button>
</template>

<script setup lang="ts">
defineProps<{
  label: string;
}>();

const scroll = () => {
  if (process.client) {
    scrollToTop();
  }
};
</script>
