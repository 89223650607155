export default defineNuxtRouteMiddleware((to, _) => {
  if (to.path.includes("/G07C0NV_")) {
    const path = to.path.split("/G07C0NV_")[0];
    return navigateTo(path, { redirectCode: 301 });
  }
  if (to.path.includes(";jsessionid=")) {
    const path = to.path.split(";jsessionid=")[0];
    return navigateTo(path, { redirectCode: 301 });
  }
  if (to.path.startsWith("/reise")) {
    const path = to.path.split("/reise")[1];
    return navigateTo(path, { redirectCode: 301 });
  }
  if (to.path.startsWith("/schiff/kabinen")) {
    const path = to.path.split("/schiff/kabinen")[1];
    return navigateTo(path, { redirectCode: 301 });
  }
  if (to.path.startsWith("/schiff/einrichtungen")) {
    const path = to.path.split("/schiff/einrichtungen")[1];
    return navigateTo(path, { redirectCode: 301 });
  }
  if (to.path.startsWith("/schiff/decksplaene")) {
    const path = to.path.split("/schiff/decksplaene")[1];
    return navigateTo(path, { redirectCode: 301 });
  }
  if (to.path.startsWith("/schiff/bewertungen")) {
    const path = to.path.split("/schiff/bewertungen")[1];
    return navigateTo(path, { redirectCode: 301 });
  }
  if (to.path.startsWith("/schiff/galerie")) {
    const path = to.path.split("/schiff/galerie")[1];
    return navigateTo(path, { redirectCode: 301 });
  }
  if (to.path.startsWith("/schiff")) {
    const path = to.path.split("/schiff")[1];
    return navigateTo(path, { redirectCode: 301 });
  }
  if (to.path.startsWith("/reederei/club")) {
    const path = to.path.split("/reederei/club")[1];
    return navigateTo(path, { redirectCode: 301 });
  }
});
