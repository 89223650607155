import { defaultApiHeaders } from "./useGo7seasApi";
import type { Dia } from "~~/types/diashow";
import type { DiashowResponse } from "~~/types/response";

export const useDiaShow = () => {
  const dias: Ref<Array<Dia>> = useState("dias", () => []);

  function set(ds: Array<Dia>) {
    dias.value = ds;
  }

  function reset() {
    dias.value = [];
  }

  function get(): Array<Dia> {
    return dias.value;
  }

  function has(): ComputedRef<boolean> {
    return computed(() => dias.value.length > 0);
  }

  function fetch(url: string): Promise<void> {
    return $fetch<DiashowResponse>(url, { headers: defaultApiHeaders() })
      .then((response) => {
        dias.value = response.dia_show.dias;
      })
      .catch(() => reset());
  }

  return { reset, get, set, has, fetch };
};
