<template>
  <footer
    v-if="footer"
    class="relative bg-g7-blue px-2 text-white lg:px-[3.438rem] lg:pb-[3.438rem]"
  >
    <div class="grid grid-cols-3 items-center lg:items-start">
      <div class="flex items-center justify-between lg:items-start">
        <FooterLinkList :content="staticLinks" />
        <FooterLinkList class="hidden lg:inline" :content="footer.menus[1]" />
      </div>
      <div
        class="col-span-2 flex flex-col items-center space-y-4 lg:col-span-1"
      >
        <IconsTop :label="footer.label_button" />
        <span class="hidden text-xl font-bold lg:inline">{{
          footer.label_social
        }}</span>
        <div class="hidden w-full justify-center space-x-6 lg:flex">
          <div v-for="(social, index) in footer.social_icons" :key="index">
            <NuxtLink target="_blank" :to="social.link">
              <div
                class="flex size-10 items-center justify-center rounded-full bg-white"
              >
                <component
                  :is="
                    socialLinks.find((item) => item.icon === social.icon)
                      ?.component
                  "
                  class="size-8 fill-g7-blue"
                />
              </div>
            </NuxtLink>
          </div>
        </div>
        <div class="flex flex-col items-center lg:pt-24">
          <NuxtLink to="/">
            <CloudinaryImg
              public-id="go7seas-logo"
              :steps="[200, 400, 600]"
              class="mb-2 w-36 sm:w-40 md:mb-5"
            />
          </NuxtLink>
          <span
            class="text-center text-sm sm:text-sm md:text-xl"
            v-html="footer.label_name"
          />
        </div>
      </div>
      <div class="flex items-center justify-between lg:items-start">
        <FooterLinkList class="hidden lg:inline" :content="footer.menus[2]" />
        <FooterLinkList class="hidden lg:inline" :content="footer.menus[3]" />
      </div>
    </div>
  </footer>
</template>

<script setup lang="ts">
const props = defineProps<{
  offline?: boolean;
}>();

const layout = useDefaultLayout();
const footer = computed(() => layout.value.footer);

const socialLinks = reactive([
  {
    icon: "fab fa-facebook-f fa-stack-1x icon-dark",
    component: shallowRef(resolveComponent("IconsFacebook")),
  },
  {
    icon: "fab fa-twitter fa-stack-1x icon-dark",
    component: shallowRef(resolveComponent("IconsXTwitter")),
  },
  {
    icon: "fab fa-instagram fa-stack-1x icon-dark",
    component: shallowRef(resolveComponent("IconsInstagram")),
  },
]);

const staticLinks = {
  title: "Info & Service",
  items: [
    { label: "Impressum", context: "/service/impressum" },
    { label: "AGB", context: "/service/agb" },
    { label: "Datenschutz", context: "/service/datenschutz" },
    {
      label: "Cookie-Einstellungen",
      context: "javascript:UC_UI.showSecondLayer();",
    },
  ],
};

if (!props.offline) {
  staticLinks.items.push(
    ...[
      { label: "Call-Back-Service", context: "/callback" },
      { label: "Kontakt", context: "/kontakt" },
    ]
  );
}
</script>
