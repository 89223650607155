export const useLoading = (loading: boolean = null): Ref<boolean> => {
  const state = useState("loading", () => false);
  if (loading !== null) {
    state.value = loading;
  }
  return state;
};

export const useApiLoading = (loading: boolean | null = null): Ref<boolean> => {
  const state = useState("apiLoading", () => false);
  if (loading !== null) {
    state.value = loading;
  }
  return state;
};
