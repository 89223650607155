<template>
  <div v-if="content" class="my-2 lg:my-[3.438rem]">
    <div
      class="mb-2 border-b-2 text-sm font-bold uppercase md:text-base lg:mb-4"
      v-html="content.title"
    />
    <ul>
      <li
        v-for="(item, index) in content.items"
        :key="index"
        class="leading-[0.5rem]"
      >
        <div
          v-if="hasError && !item.context?.includes('javascript')"
          class="cursor-pointer"
          @click="clearError({ redirect: item.context })"
        >
          <span class="text-sm sm:text-sm md:text-base" v-html="item.label" />
        </div>
        <NuxtLink v-else :to="item.context">
          <span class="text-sm sm:text-sm md:text-base" v-html="item.label" />
        </NuxtLink>
      </li>
    </ul>
  </div>
</template>

<script lang="ts" setup>
import type { FooterMenu } from "~/types/layout";
const hasError = useError();

defineProps<{
  content?: FooterMenu;
}>();
</script>
